import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-progress-steps',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-steps.component.html',
  styleUrl: './progress-steps.component.scss',
})
export class ProgressStepsComponent implements OnChanges {
  @Input({ required: true }) totalSteps!: number;
  @Input() currentStep!: number;
  @Input() iconImageSrc!: string;
  @Input() iconImageAlt!: string;

  activeStep!: number;
  totalStepsArray!: number[];
  activeProgressWidth!: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.totalStepsArray = Array.from(Array(this.totalSteps).keys());
    this.activeStep = this.currentStep - 1;

    this.activeProgressWidth =
      (this.activeStep / (this.totalSteps - 1)) * 100 + '%';
  }
}
