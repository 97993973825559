<div class="container">
  <div class="progress-container">
    <div class="progress" [ngStyle]="{ width: activeProgressWidth }"></div>

    <!-- additional steps -->
    @for(step of totalStepsArray; track step.valueOf) {

    <div
      class="circle"
      [ngClass]="{ active: step === activeStep, touched: step < activeStep }"
    >
      @if(step === activeStep) {

      <img [src]="iconImageSrc" [alt]="iconImageAlt" class="h-6 w-6" />

      }
    </div>
    }
  </div>
</div>
